import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';

export default class TermsOfUse extends React.Component {
	render() {
		return (
			<Layout
				title={seo.terms.title}
				description={seo.default.description}
				className="max-w-5xl mx-auto px-8 lg:px-16 py-12"
			>
				<nav>
					<ul>
						<li className="mb-4">
							<a href="#acceptance-of-terms-of-use">
								Acceptance Of Terms Of Use
							</a>
						</li>
						<li className="mb-4">
							<a href="#terms-of-use">TERMS OF USE</a>
						</li>
						<li className="mb-4">
							<a href="#no-advice">No Advice</a>
						</li>
						<li className="mb-4">
							<a href="#description-of-the-service">
								Description of the Service
							</a>
						</li>
						<li className="mb-4">
							<a href="#restrictions">Restrictions</a>
						</li>
						<li className="mb-4">
							<a href="#unique-identifiers">
								Sign-in Name; Password; Unique Identifiers
							</a>
						</li>
						<li className="mb-4">
							<a href="#information-supplied-and-using-our-websites">
								Information Supplied and Using Our Websites
							</a>
						</li>
						<li className="mb-4">
							<a href="#payment">Payment</a>
						</li>
						<li className="mb-4">
							<a href="#credit-card-terms">Credit Card Terms</a>
						</li>
						<li className="mb-4">
							<a href="#intellectual-property">
								Intellectual Property
							</a>
						</li>
						<li className="mb-4">
							<a href="#communications">
								Communications To HomeKeepr; Participation
								Disclaimer
							</a>
						</li>
						<li className="mb-4">
							<a href="#representations-and-warranties">
								Representations and Warranties
							</a>
						</li>
						<li className="mb-4">
							<a href="#external-websites">External Websites</a>
						</li>
						<li className="mb-4">
							<a href="#indemnification">Indemnification</a>
						</li>
						<li className="mb-4">
							<a href="#dmca">Digital Millennium Copyright Act</a>
						</li>
						<li className="mb-4">
							<a href="#compliance-with-applicable-laws">
								Compliance With Applicable Laws
							</a>
						</li>
						<li className="mb-4">
							<a href="#termination-of-the-agreement">
								Termination of the Agreement
							</a>
						</li>
						<li className="mb-4">
							<a href="#privacy">Privacy</a>
						</li>
						<li className="mb-4">
							<a href="#ada-compliance">ADA COMPLIANCE</a>
						</li>
						<li className="mb-4">
							<a href="#miscellaneous">Miscellaneous</a>
						</li>
					</ul>
				</nav>
				<article id="acceptance-of-terms-of-use">
					<h1 className="mb-4">Acceptance Of Terms Of Use</h1>
					<p className="mb-4">
						We’re excited that you have decided to access subject to
						the terms and conditions (the “Terms of Use”) set forth
						herein the HomeKeepr.com website (the “User Website”),
						homeowners.HomeKeepr.com (the “Homeowner Website”),
						pros.HomeKeepr.com(the “Real Estate Professional
						Website”), brokers.HomeKeepr.com (the “Broker Website”)
						(collectively the “Websites”), our Facebook and/or our
						other social media plugins (the “Social Media Plugins”),
						and/or our mobile applications (the “Mobile Apps,” and
						collectively with the Social Media Plugins and the
						Websites, the “Service”). This document constitutes a
						legally binding agreement governing the terms and
						conditions of providing you with our Service. The terms
						“we,” “us,” “our,” and “HomeKeepr” refer to MooveGuru,
						Inc, the company that owns and operates the Websites and
						the Mobile Apps, and any of its affiliates, officers,
						employees, agents, insurers, joint ventures, partners,
						successor, transferees and assigns (hereinafter
						“Company”).
					</p>
					<p className="mb-4">
						HomeKeepr provides its services to you subject to the
						following Terms of Use, which may be updated by us from
						time to time without notice to you. By browsing the
						public areas or by accessing and using the Service,
						including all content available through the Websites,
						you acknowledge that you have read, understood, and
						agree to be legally bound by the terms and conditions of
						these Terms of Use and the terms and conditions of
						HomeKeepr’s Privacy Policy, which is hereby incorporated
						by reference (collectively, this “Agreement.”). If you
						do not agree to any of these terms, then please do not
						use our Service.
					</p>
					<section id="terms-of-use">
						<h2 className="mb-4">TERMS OF USE</h2>
						<section id="no-advice">
							<h3 className="mb-4">No Advice</h3>
							<p className="mb-4">
								<strong>
									THIS WEBSITE DOES NOT PROVIDE ADVICE. The
									Website and the Service are provided for
									informational purposes only.
								</strong>{' '}
								Other Registered Users may refer a Service
								Provider to you through the Service, but
								HomeKeepr is not a referral service, and neither
								HomeKeepr nor Registered Users, Real Estate
								Professionals or other users recommend or
								endorse in any way any specific Service
								Providers, nor do they guaranty or warranty the
								Services in any way. Reliance on the Websites
								and the Service, and any data or content, is
								solely at your own risk. Any suggestion by a
								Real Estate Professional is in no way endorsed
								or suggested by the Real Estate Professional’s
								applicable real estate broker. Nothing in the
								Service is intended to provide Legal advice and
								such is expressly disclaimed hereby.
							</p>
							<p className="mb-4">
								Berkshire Hathaway HomeServices does not endorse
								any of the products or vendors referenced on
								this material. Any mention of vendors, products
								or services is for informational purposes only.
							</p>
						</section>
						<section id="description-of-the-service">
							<h3 className="mb-4">Description of the Service</h3>
							<p className="mb-4">
								Among other things, HomeKeepr is a search engine
								that permits consumers, real estate
								professionals (“Real Estate Professionals”), and
								service professionals (“Service Providers”) to
								connect with each other. It also allows for Real
								Estate Professionals to provide a list of
								Service Providers for customers’ and clients’
								use.
							</p>
							<p className="mb-4">
								If you are a Registered User or Visitor, you can
								search for what you need using our Service, and
								we will display professionals who can provide
								the necessary service. Nothing in the Service is
								providing a referral or recommendation to use a
								particular Real Estate Professional or Service
								Provider.
							</p>
							<p className="mb-4">
								If you are a Service Professional, we will try
								to do our best to provide you with advertising
								and marketing tools.
							</p>
							<p className="mb-4">
								If you are a Real Estate Professional, we will
								provide a mechanism to connect your customers
								and clients who become Registered Users or
								Visitors with you and your preferred Service
								Providers provided you complete all of the
								relevant forms.
							</p>
							<p className="mb-4">
								In addition, the Service is dedicated to
								enabling Registered Users to share information
								about Service Providers with each other. All of
								the products and services that we offer on this
								Service are referred to in these Terms of Use
								collectively as the “Service.” The Company is no
								and shall not be responsible or liable for any
								information about Service Providers or Real
								Estate Professional published or shared.
							</p>
							<p className="mb-4">
								HomeKeepr provides Visitors and Registered Users
								with access to the Service as described in this
								Agreement.
							</p>
							<p className="mb-4">
								Visitors. Visitors, as the term implies, are
								people who don’t register with us, but want to
								see what the Service is all about. No login is
								required for Visitors. Visitors can (a) view all
								publicly-accessible content and (b) e-mail us.
								Visitors are subject to the Terms of Use and
								this Agreement regardless of registration.
							</p>
							<p className="mb-4">
								Registered Users. Login is required for all
								Registered User services. Registered Users can
								do all the things that Visitors can do, and also
								(a) search for Service Providers, (b) review
								information about Service Providers, such as
								contact information, professional affiliations,
								education, etc., (c) access comments about
								Service Providers from other Registered Users,
								(d) create feedback and comments, (e) send
								emails, text messages, or push notifications to
								other Registered Users, (f) request friends to
								register for the Service, (g) sign up for
								promotions and sweepstakes and (h) blog about
								their experiences with the Service.
							</p>
							<p className="mb-4">
								Real Estate Professionals. Real Estate
								Professionals include, but are not limited to,
								real estate agents, real estate brokers, and
								mortgage professionals. Real Estate
								Professionals can do all the things that
								Visitors and Registered Users can do and they
								can also (a) enhance their profiles, (b) invite
								Visitors and Registered Users to join the
								Service, and (c) provide a list of Service
								Providers for customers’ and clients’ use.
							</p>
							<p className="mb-4">
								Service Providers. Service Providers are service
								providing businesses. Service Providers can do
								all the things that Visitors and Registered
								Users can do and they can also submit requests
								to update and enhance their profiles.
							</p>
							<p className="mb-4">
								HomeKeepr is under no obligation to accept any
								individual as a Visitor, Registered User, a Real
								Estate Professional or a Service Provider, and
								may accept or reject any registration in its
								sole and complete discretion. Company may block
								any Visitor at any time for any or no reason.
							</p>
							<p className="mb-4">
								Also, in order to use the Service via our Mobile
								Apps, Registered Users, Real Estate
								Professionals and Service Providers hereby
								consent to: (a) the use of their Mobile Device’s
								Geolocational Information to provide the
								Service; (b) receive text messages; and (c) pay
								carrier data, messaging, and other fees
								resulting from their usage of the Service.
							</p>
						</section>
						<section id="restrictions">
							<h3 className="mb-4">Restrictions</h3>
							<p className="mb-4">
								The Service is available only to individuals and
								entities that can form legally binding contracts
								under applicable law. By accessing, downloading,
								and/or otherwise using the Websites and/or the
								Service, you (a) certify that you are at least
								18 years of age and (b) have read and agree to
								be legally bound by these Terms of Use. In
								accordance with Federal Children’s Online
								Privacy Protection Act of 1998 (COPPA), we will
								never knowingly solicit, nor will we accept,
								personally identifiable information from users
								known to be under thirteen (13) years of age.
								Please see our Privacy Policy, which is hereby
								incorporated herein by reference, for additional
								information or for how to notify us of any
								concerns you may have in this regard.
							</p>
						</section>
						<section id="unique-identifiers">
							<h3 className="mb-4">
								Sign-in Name; Password; Unique Identifiers
							</h3>
							<p className="mb-4">
								During the registration process for Registered
								Users, we will ask you to create an account,
								which includes a unique sign-in name (“Sign-In
								Name”), password (“Password”), and perhaps
								certain additional information that will assist
								in authenticating your identity when you log-in
								in the future (“Unique Identifiers”). When
								creating your account, you must provide true,
								accurate, current, and complete information.
								Each Sign-In Name and corresponding Password can
								be used by only one Registered User. You are
								solely responsible for the confidentiality and
								use of your Sign- In Name, Password, and Unique
								Identifiers, as well as for any use, misuse, or
								communications entered through the Websites
								and/or Mobile Apps using one or more of them.
								You will promptly inform HomeKeepr of any need
								to deactivate a Password or Sign-In Name, or
								change any Unique Identifier. HomeKeepr and
								company reserves the right to delete or change
								your Password, Sign-In Name, or Unique
								Identifier at any time and for any or no reason.
								HomeKeepr and Company will not be liable for any
								loss or damage caused by any unauthorized use of
								your account, malware, corrupted data, computer
								viruses, defective or invasive software.
							</p>
							<p className="mb-4">
								You may also register for and use the Service
								through Facebook and/or other third- party
								Social Media sites, in which case we will use
								the information provided by such Social Media
								sites to complete your registration. For more
								information about the Personal Information we
								may receive from third-party Social Media sites,
								please refer to our Privacy Policy. Company
								shall have no responsibility or liability for
								loss or damages related to, arising from, or
								involving any Social Media websites or
								applications.
							</p>
						</section>
						<section id="information-supplied-and-using-our-websites">
							<h3 className="mb-4">
								Information Supplied and Using Our Websites
							</h3>
							<p className="mb-4">
								In order for you to sign up with us, you may be
								required to provide your name, e-mail address,
								and zip code. We will also ask you general
								questions about the services you are requesting.
							</p>
							<p className="mb-4">
								In addition to providing us with the above
								information, you must be at least eighteen years
								old to sign up for our service, or, if a higher
								age of contractual capacity exists in your
								jurisdiction, then you must be at least that
								age.
							</p>
							<p className="mb-4">
								In addition, when interacting with our Websites
								or the Service or the Company you agree that:
							</p>
							<ul>
								<li className="mb-4">
									You will not deceive us or any of the
									Service Providers or Real Estate
									Professionals on our platform;
								</li>
								<li className="mb-4">
									You will not use the site or the HomeKeepr
									platform to engage in any illegal conduct,
									allude to or request any illegal services;
								</li>
								<li className="mb-4">
									You will not request Services for anyone
									with whom you do not have authority to
									conduct business on their behalf;
								</li>
								<li className="mb-4">
									You will not harass and/or stalk any
									employee, member, the Company, Real Estate
									Professional, or Service Provider or behave
									in lewd, disparaging, political, or
									inappropriate conduct or communications;
								</li>
								<li className="mb-4">
									You will not post, submit, or convey any
									false, misleading, unlawful, defamatory,
									untruthful, inaccurate, obscene, invasive,
									threatening, harassing, inflammatory, or
									fraudulent content, hate speech or
									information;
								</li>
								<li className="mb-4">
									You will not impersonate any person, entity,
									or company;
								</li>
								<li className="mb-4">
									You will not use our Service to send “spam”,
									texts, phone calls, facsimiles, or other
									unsolicited commercial or non-commercial
									material to any of our other members or
									other legal persons;
								</li>
								<li className="mb-4">
									You will not use us to “secret shop” other
									businesses;
								</li>
								<li className="mb-4">
									You will not hack, crack, phish, SQL inject,
									install any code that is malicious, invasive
									or manipulates the site, data or content in
									any way, or otherwise compromise the
									security, safety, or privacy of our Service
									or our network, servers, member accounts,
									mobile numbers or access to the numbers, or
									the computers of our users;
								</li>
								<li className="mb-4">
									You will not engage or solicit any other
									users to buy or sell products or services
									unless expressly authorized by the Service;
								</li>
								<li className="mb-4">
									You will not share your login and password
									with any legal person;
								</li>
								<li className="mb-4">
									You will not run any script or other program
									which is designed to search, index, or
									aggregate the User Content or other
									information on our Service, unless we have
									given you our express written consent to do
									so, which we may revoke at any time;
								</li>
								<li className="mb-4">
									You will not post any reviews that are
									false, defamatory, disparaging,
									inappropriate, political or invade someone’s
									privacy (if we give you the option of
									providing a review)
								</li>
							</ul>
						</section>
						<section id="payment">
							<h3 className="mb-4">Payment</h3>
							<p className="mb-4">
								Our Service is currently free to use for
								Registered Users and temporarily for Visitors.
							</p>
							<p className="mb-4">
								If you are a Real Estate Professional you may be
								charged a monthly or annual subscription fee for
								the Service only if you have selected a paid
								plan. If you are a Service Provider, you will
								have the option, but not the obligation, to
								purchase various enhanced advertising
								opportunities. For example, if you choose to
								participate in a paid plan we may charge a fee
								for Service Providers to obtain preferred
								placement in the list of Service Providers,
								provision of additional information in your
								profile, promotional endeavors and other
								preferred provider items that we may offer from
								time-to-time. Although HomeKeepr or Company may
								offer payment by receipt of a check in certain
								instances, the main method of payment will be
								through the use of a credit card that Service
								Providers and Real Estate Professionals would
								include as part of their profile.
							</p>
						</section>
						<section id="credit-card-terms">
							<h3 className="mb-4">Credit Card Terms</h3>
							<p className="mb-4">
								As a Service Provider or Real Estate
								Professional, you will be required to pay almost
								all fees via a credit card. As such, you hereby
								agree to the following terms and conditions
								related to credit card use:
							</p>
							<p className="mb-4">
								By entering your credit card information:
							</p>
							<ol>
								<li className="mb-4">
									You are stating that you are an authorized
									user of the credit card and that the
									associated information entered (account
									holder name, account number, billing
									address, etc.) is accurate and complete.
								</li>
								<li className="mb-4">
									You authorize HomeKeepr or the Company to
									charge the amount you have requested to your
									credit card.
								</li>
								<li className="mb-4">
									If you set up automatic payments, then you
									authorize HomeKeepr or the Company to charge
									the amount due for the invoice being paid to
									the credit card.
								</li>
								<li className="mb-4">
									You also authorize HomeKeepr or the Company
									to return to your credit card any funds due
									to you by HomeKeepr resulting from use of
									this Service.
								</li>
								<li className="mb-4">
									For each transaction, in addition to the
									charge you have authorized, your credit card
									issuer and network may assess their
									customary transaction or handling charge, if
									any.
								</li>
								<li className="mb-4">
									If a charge is declined or reversed by the
									credit card issuer or network, you agree to
									pay us a service charge and to reimburse us
									for all reasonable costs of collection. Your
									credit card issuer may also assess its
									customary charge for such transactions. You
									agree that such service charge of $__50_ is
									fair and reasonable.
								</li>
								<li className="mb-4">
									If your credit card issuer or network does
									not honor an online payment transaction,
									then we have the right to charge the amount
									of any such transaction to your account or
									to collect the amount from you.
								</li>
								<li className="mb-4">
									If your credit card issuer or network does
									not honor an online payment transaction, we
									may terminate any or all Service, and we may
									cancel your right to participate in the
									Service altogether in our sole discretion.
								</li>
								<li className="mb-4">
									By clicking “Submit,” you are consenting to
									receive a one-time confirmation of this
									payment electronically to the email address
									you have provided to us.
								</li>
								<li className="mb-4">
									If you set up automatic payments, then you
									are consenting to receive a one-time
									confirmation of each payment electronically
									to the email address you have provided to
									us.
								</li>
								<li className="mb-4">
									In order to make credit card payments online
									or receive electronic communications, you
									must have access to a personal computer with
									a 128 bit JavaScript enabled browser,
									internet access and a valid email account
									supported by software that enables you to
									receive electronic communications. In order
									to store electronic communications on paper,
									you will need a printer connected to your
									computer. You also may store them in your
									offline files or in an electronic storage
									device. HomeKeepr and/or Company is not
									required to provide paper copies of any
									electronic Communication you have previously
									authorized.
								</li>
								<li className="mb-4">
									You agree to provide us, and keep current, a
									valid email address. If any electronic
									communication is returned to us as
									undeliverable, you agree that we may (but
									are not required to) deliver such
									communication in paper form to the most
									recent mailing address you have provided for
									your account. Failure to comply with the
									restriction may result in termination of the
									Service.
								</li>
								<li className="mb-4">
									By authorizing a credit card payment online,
									or by requesting electronic communications,
									you confirm that your system meets these
									requirements, that you have the capability
									to access and download or print electronic
									disclosures, and that your email address is
									current and valid.
								</li>
							</ol>
						</section>
						<section id="intellectual-property">
							<h3 className="mb-4">Intellectual Property</h3>
							<p className="mb-4">
								The Service contains material, such as software,
								text, data, graphics, images, sound recordings,
								know how, audiovisual works, intellectual
								property and other material provided by or on
								behalf of HomeKeepr and/or the Company
								(collectively referred to as the “HomeKeepr
								Content”). The HomeKeepr Content may be owned by
								us or by third parties. The HomeKeepr Content is
								protected under both United States,
								international law, and foreign laws, including
								customary international law including jus
								cugens. Unauthorized use of the HomeKeepr
								Content may violate copyright, trademark,
								patent, treaty, and other laws. We give you
								permission, which may be deemed an exclusive,
								worldwide, royalty free revocable license for
								any reason or no reason at anytime, to use the
								aforementioned content for personal,
								non-commercial purposes only and do not transfer
								any intellectual property rights to you by
								virtue of permitting your use of the Websites or
								the Service. You may print, download, and store
								information from the Service for your own
								convenience, but you may not copy, distribute,
								republish (except as permitted in this
								paragraph), sell, or exploit any of the content,
								or exploit the Service in whole or in part, for
								any commercial gain or purpose whatsoever except
								as expressly provided herein. You must retain
								all copyright, trademark, patent and other
								proprietary notices contained in the original
								HomeKeepr Content on any copy you make of the
								HomeKeepr Content.
							</p>
							<p className="mb-4">
								The trademarks, service marks, and logos of
								HomeKeepr and/or the Company (the HomeKeepr
								Trademarks”) used and displayed on the Service
								are registered and unregistered trademarks
								and/or service marks of MooveGuru, Inc. Other
								company, product, and service names located on
								the Service may be trademarks or service marks
								owned by others (the “Third-Party Trademarks”,
								and, collectively with HomeKeepr Trademarks, the
								“Trademarks”). Nothing on the Service should be
								construed as granting, by implication, estoppel,
								or otherwise, any license or right to use the
								Trademarks, without our prior written permission
								specific for each such use. Use of the
								Trademarks as part of a link to or from any site
								is prohibited unless establishment of such a
								link is approved in advance by us in writing.
								All goodwill generated from the use of HomeKeepr
								Trademarks and other intellectual property of
								whatever kind and nature inures to HomeKeepr’s
								benefit.
							</p>
							<p className="mb-4">
								Elements of the Service are protected by trade
								dress, trademark, unfair competition, and other
								state and federal and international laws and may
								not be copied or imitated in whole or in part,
								by any means, including but not limited to the
								use of framing or mirrors. None of the HomeKeepr
								Content for this Service may be retransmitted
								decompiled, reverse engineered, or otherwise
								manipulated without our express, written consent
								for each and every instance.
							</p>
						</section>
						<section id="communications">
							<h3 className="mb-4">
								Communications To HomeKeepr; Participation
								Disclaimer
							</h3>
							<p className="mb-4">
								Although we encourage you to e-mail us, we do
								not want you to, and you should not, e- mail us
								any content that contains confidential or
								private information. With respect to all e-mails
								you send to us, including but not limited to,
								feedback, questions, comments, suggestions, and
								the like, we shall be free to use any ideas,
								concepts, know-how, or techniques contained in
								your communications for any purpose whatsoever,
								including but not limited to, the development,
								production and marketing of products and
								services that incorporate such information.
							</p>
							<p className="mb-4">
								HomeKeepr and/or the Company does not and cannot
								review all communications and materials posted
								to or created by users accessing the Service
								(hereinafter, “User Generated Content”), and is
								not in any manner responsible for the content of
								the User Generated Content. Remember that the
								User Generated Content is simply opinion and
								should not be relied on. You acknowledge that by
								providing you with the ability to view and
								distribute User Generated Content on the
								Service, HomeKeepr and/or the Company is merely
								acting as a passive conduit for such
								distribution and is not undertaking any
								obligation or liability relating to any contents
								or activities on the Service. However, HomeKeepr
								reserves the right to block or remove
								communications or materials that it determines
								to be (a) abusive, defamatory, disparaging, or
								obscene, (b) fraudulent, deceptive, or
								misleading, (c) in violation of a copyright,
								trademark or; other intellectual property right
								of another, (d) political or offensive or
								otherwise unacceptable to HomeKeepr and/or the
								Company in its sole discretion.
							</p>
							<p className="mb-4">
								You retain all copyrights and other intellectual
								property rights in and to anything you post to
								the Service, including User Generated Content,
								text and photographs. You do, however, grant us
								an irrevocable, non-exclusive, worldwide,
								perpetual, royalty-free license to use, modify,
								copy, distribute, publish, perform, sublicense,
								and create derivative works from all submissions
								or compilations of submissions or data you
								provide to us in any media now known or
								hereafter to advertise and promote the Service
								and/or HomeKeepr.
							</p>
						</section>
						<section id="representations-and-warranties">
							<h3 className="mb-4">
								Representations and Warranties
							</h3>
							<p className="mb-4">
								As mentioned before, we are an intermediary
								between you and potential Service Providers. Any
								issues that may arise concerning the Service
								Provider and you, are between you and them.
								While we may have certain responsibilities
								connected with our platform, those
								responsibilities are limited. We may terminate
								any relationship we have with either you or the
								Service Provider in performing our services, the
								service without notice, and without any
								compensation for any loss, harm or injury.
							</p>
							<p className="mb-4">
								REGISTERED USERS, VISITORS, REAL ESTATE
								PROFESSIONALS, SERVICE PROVIDERS, AND OTHER
								USERS OF THE SERVICE AND THE WEBSITES ASSUME
								TOTAL RESPONSIBILITY AND RISK FOR THEIR OWN USE
								OF THE WEBSITES AND THE USE OF ANY SERVICE
								PROVIDERS SERVICE, OR PARTICIPATION IN ANY OF
								OUR RELATED SERVICES. HOMEKEEPR PROVIDES THE
								SERVICE AND WEBSITES AND RELATED INFORMATION “AS
								IS WHERE IS” AND ON AN “AS AVAILABLE” BASIS, AND
								DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES,
								REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER
								(INCLUDING WITHOUT LIMITATION WARRANTIES OF
								TITLE OR NON INFRINGEMENT, OR THE IMPLIED
								WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
								PARTICULAR PURPOSE) WITH REGARD TO THE WEBSITES,
								SERVICE, ANY PRODUCT OR SERVICE PROVIDER
								INFORMATION PROVIDED THROUGH THE WEBSITES, OR
								VIA THIRD PARTY LINKS, OR VIA ANY BROADCAST TO
								PROVIDERS, AND HOMEKEEPR AND/OR THE COMPANY
								SHALL NOT BE LIABLE FOR ANY COST OR DAMAGE
								ARISING EITHER DIRECTLY OR INDIRECTLY FROM ANY
								SUCH USE. IT IS SOLELY YOUR RESPONSIBILITY TO
								EVALUATE THE ACCURACY, COMPLETENESS AND
								USEFULNESS OF ALL SERVICE PROVIDERS, ADVICE, OR
								ANY SERVICE OR OTHER INFORMATION PROVIDED
								THROUGH THE SITE OR VIA THE SERVICE. WE DO NOT
								WARRANT THAT THE WEBSITES WILL BE UNINTERRUPTED
								OR ERROR-FREE OR THAT DEFECTS IN THE WEBSITES
								WILL BE CORRECTED.
							</p>
							<p className="mb-4">
								IF YOU, OR THE ENTITY YOU REPRESENT, ARE LOCATED
								IN A JURISDICTION THAT REQUIRES A SPECIFIC
								STATEMENT REGARDING RELEASE THEN THE FOLLOWING
								APPLIES. FOR EXAMPLE, CALIFORNIA RESIDENTS MUST,
								AS A CONDITION OF THIS AGREEMENT, WAIVE THE
								APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION
								1542, WHICH STATES, “A GENERAL RELEASE DOES NOT
								EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
								KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT
								THE TIME OF EXECUTING THE RELEASE, WHICH IF
								KNOWN BY HIM OR HER MUST HAVE MATERIALLY
								AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
								YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA
								CIVIL CODE. YOU HEREBY WAIVE ANY SIMILAR
								PROVISION IN LAW, REGULATION, OR CODE THAT HAS
								THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED
								RELEASE IN OTHER JURISDICTION.
							</p>
							<p className="mb-4">
								WE DISCLAIM ALL LIABILITY TO THE FULLEST EXTENT
								PERMITTED BY APPLICATION. WE WILL NOT BE
								RESPONSIBLE FOR ANY DAMAGES, WHETHER DIRECT OR
								INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY,
								PUNITIVE, OR OTHER, EVEN IF WE KNEW OR SHOULD
								HAVE KNOWN. OUR LIABILITY IN ANY JURISDICTION
								THAT DOES NOT ALLOW FOR THE EXCLUSION OR
								LIMITATION OF LIABILITY, THEN TO THE EXTENT
								PERMITTED BY LAW BUT IN NO EVENT GREATER THAN
								THE AMOUNT YOU HAVE PAID UNDER THIS CONTRACT FOR
								THE PAST YEAR.
							</p>
						</section>
						<section id="external-websites">
							<h3 className="mb-4">External Websites</h3>
							<p className="mb-4">
								The Service may contain links to third-party
								websites (“External Websites”). These links are
								provided solely as a convenience to you and not
								as an endorsement by us of the content on such
								External Websites. The content of such External
								Websites is developed and provided by others.
								You should contact the site administrator or
								webmaster for those External Websites if you
								have any concerns regarding such links or any
								content located on such External Websites. We
								are not responsible for the content of or
								interfacing of any linked External Websites and
								do not make any representations regarding the
								content or accuracy of materials on such
								External Websites. You should take precautions
								when downloading files from all websites to
								protect your computer and related devices from
								viruses and other destructive programs. If you
								decide to access linked External Websites, you
								do so at your own risk.
							</p>
						</section>
						<section id="indemnification">
							<h3 className="mb-4">Indemnification</h3>
							<p className="mb-4">
								You agree to defend, indemnify, and hold us the
								Company and our officers, directors, employees,
								successors, transferees, licensees, and assigns
								forever harmless from and against any claims,
								actions, or demands, including, without
								limitation, reasonable legal and accounting
								fees, arising or resulting from your breach of
								this Agreement or your access to, use, or misuse
								of the HomeKeepr Content, the Websites, or the
								Service. We shall provide notice to you of any
								such claim, suit, or proceeding and shall assist
								you, at your expense, in defending any such
								claim, suit, or proceeding. We reserve the right
								to assume the exclusive defense and control of
								any matter that is subject to indemnification
								under this section. In such case, you agree to
								cooperate with any reasonable requests assisting
								our defense of such matter.
							</p>
						</section>
						<section id="dmca">
							<h3 className="mb-4">
								Digital Millennium Copyright Act
							</h3>
							<p className="mb-4">
								HomeKeepr respects the intellectual property
								rights of others and attempts to comply with all
								relevant laws. We will review all claims of
								copyright infringement received and remove User
								Generated Content deemed to have been posted or
								distributed in violation of any such laws.
							</p>
							<p className="mb-4">
								Our designated agent under the Digital
								Millennium Copyright Act (the “Act”) for the
								receipt of any Notification of Claimed
								Infringement which may be given under that Act
								is as follows:
							</p>
							<address>
								Name: MooveGuru, Inc
								<br />
								Address: 885 Woodstock Rd, Suite 430-206,
								Roswell, GA 30075
								<br />
								Tel:{' '}
								<a href="tel:917-688-4482">(917) 688-4482</a>
								<br />
								Email:{' '}
								<a href="mailto:info@HomeKeepr.com">
									info@HomeKeepr.com
								</a>
							</address>
							<p className="mb-4">
								If you believe that your work has been copied on
								the Service in a way that constitutes copyright
								infringement, please provide our agent with
								notice in accordance with the requirements of
								the Act, including (i) a description of the
								copyrighted work that has been infringed and the
								specific location on the Service where such work
								is located; (ii) a description of the location
								of the original or an authorized copy of the
								copyrighted work; (iii) your address, telephone
								number and e-mail address; (iv) a statement by
								you that you have a good faith belief that the
								disputed use is not authorized by the copyright
								owner, its agent or the law; (v) a statement by
								you, made under penalty of perjury, that the
								information in your notice is accurate and that
								you are the copyright owner or authorized to act
								on the copyright owner’s behalf; and (vi) an
								electronic or physical signature of the owner of
								the copyright or the person authorized to act on
								behalf of the owner of the copyright interest.
							</p>
						</section>
						<section id="compliance-with-applicable-laws">
							<h3 className="mb-4">
								Compliance With Applicable Laws
							</h3>
							<p className="mb-4">
								The Service is based in the United States. We
								make no claims concerning whether the Service
								content may be downloaded, viewed, or be
								appropriate for use outside of the United
								States. If you access the Service or the content
								from outside of the United States, you do so at
								your own risk. Whether inside or outside of the
								United States, you are solely responsible for
								ensuring compliance with the laws of your
								specific jurisdiction. You may not use our site
								for any unlawful purpose, or for any other
								purpose that is prohibited by these Terms of
								Use. Also, you may not interfere or attempt to
								interfere with the proper operation of our
								Service, including through the use of any
								device, software or routine, or access or
								attempt to gain access to any data, files or
								passwords related to our Service through
								hacking, password mining or any other means.
							</p>
						</section>
						<section id="termination-of-the-agreement">
							<h3 className="mb-4">
								Termination of the Agreement
							</h3>
							<p className="mb-4">
								We reserve the right, in our sole discretion, to
								restrict, suspend, or terminate this Agreement
								and your access to all or any part of the
								Service, at any time and for any reason without
								prior notice or liability. We reserve the right
								to change, suspend, or discontinue all or any
								part of the Service at any time without prior
								notice or liability. You may terminate your
								HomeKeepr account at any time by contacting
								privacy@HomeKeepr.com, if you are a Registered
								User. If you are a Real Estate Professional or
								Service Provider you may terminate your account
								through the “Account Settings” tab on your
								account and in accordance with the Service
								Provider Agreement.
							</p>
						</section>
						<section id="privacy">
							<h3 className="mb-4">Privacy</h3>
							<p className="mb-4">
								To ensure the best provision of our Service to
								customers, Real Estate Professionals and Service
								Providers, we may record any telephone calls or
								online chats or other communications that may
								take place through our service. You authorize us
								to make these recordings and to store and
								distribute them as required.
							</p>
						</section>
						<section id="ada-compliance">
							<h3 className="mb-4">ADA COMPLIANCE</h3>
							<p className="mb-4">
								As an alternative to use and enjoy the website
								and the Services, you may also contact a staffed
								telephone information line T: ( ) , to gain
								access to the Service if a registered user or
								visitor.
							</p>
						</section>
						<section id="miscellaneous">
							<h3 className="mb-4">Miscellaneous</h3>
							<p className="mb-4">
								This Agreement is governed by the internal
								substantive laws of the State of Georgia,
								without respect to its conflict of laws
								provisions. You expressly agree to submit to the
								exclusive personal jurisdiction of the state and
								federal courts sitting in the State of Georgia.
								If any provision of this Agreement is found to
								be invalid by any court having competent
								jurisdiction or terminated in accordance with
								the Termination provision above, the invalidity
								or termination of such provision shall not
								affect the validity of the following provisions
								of this Agreement, which shall remain in full
								force and effect: “Intellectual Property,”
								“Communications to HomeKeepr,” “No
								Warranties/Limitation of Liability,”
								“Indemnification,” “Termination of the
								Agreement,” and “Miscellaneous.”
							</p>
							<p className="mb-4">
								Our failure to act on or enforce any provision
								of the Agreement shall not be construed as a
								waiver of that provision or any other provision
								in this Agreement. No waiver shall be effective
								against us unless made in writing, and no such
								waiver shall be construed as a waiver in any
								other or subsequent instance. Except as
								expressly agreed by us and you in writing, this
								Agreement constitutes the entire Agreement
								between you and us with respect to the subject
								matter, and supersedes all previous or
								contemporaneous agreements, whether written or
								oral, between the parties with respect to the
								subject matter. The section headings are
								provided merely for convenience and shall not be
								given any legal import. This Agreement will
								inure to the benefit of our successors, assigns,
								licensees, and sublicensees. There shall be no
								amendment or departure from this Agreement
								absent a signed document by all applicable
								parties.
							</p>
						</section>
					</section>
				</article>
			</Layout>
		);
	}
}
